import { ballNumberStyle } from '../view/TextStyles';

export default class Ball extends PIXI.Sprite {
    constructor(resources) {
        super(resources['ball']);

        this.name = 'ball',
        this.anchor.set(0.5);

        this.number = new PIXI.Text('00', ballNumberStyle);
        this.number.name = 'ball_number';
        this.number.anchor.set(0.5);
        this.number.scale.set(0.5);
        this.addChild(this.number);
    }

    setNumber(number) {
        this.number.text = number;
    }

    startRollingAnim(forward = true) {
        this._rollingTween = this.addTween().to({ rotation: Math.PI * 2 * (forward ? 1 : -1) }).loop(4).duration(2000 / 4);
    }

    stopRollingAnim() {
        // this.removeTween(this._rollingTween);
        // this.addTween().to({ rotation: 0 }).duration(100);
    }
}