import { historyContentStyle } from '../view/TextStyles';

export default class History {
    constructor(view) {
        this.content = view.getChildByName('history_lines', true);
        this.header = view.getChildByName('history_header', true);
        this.lines = [];
    }

    updateHistory(data) {
        this.moveLinesDown();

        const historyLine = new PIXI.Container();

        historyLine.position.set(-117, -10);
        historyLine.name = 'history_line';

        const draw = new PIXI.Text(data.draw, historyContentStyle);

        draw.anchor.set(0, 0.5);
        draw.scale.set(0.5);
        draw.name = 'history_line_draw';
        historyLine.addChild(draw);

        const balls = new PIXI.Text(data.combination.toString().replaceAll(',', ' '), historyContentStyle);

        balls.anchor.set(0, 0.5);
        balls.scale.set(0.5);
        balls.name = 'history_line_balls';
        balls.scale.x = 0.37;
        balls.x = 41;
        historyLine.addChild(balls);

        this.content.addChild(historyLine);
        this.lines.push(historyLine);
    }

    moveLinesDown() {
        this.lines.forEach(line => line.y += 19);

        if (this.lines.length > 3)
            this.content.removeChild(this.lines.shift());
    }

    setCurrentDraw(draw) {
        this.header.text = `ИСТОРИЯ (Текущий тираж: ${draw})`;
    }
}