import { Application } from 'core';
import GameScene from './scenes/game/GameScene';
import transport from './transport/transport';

export default class Game extends Application {
    constructor() {
        super({ width: 1920 / 3 , height: 1080 / 3 });

        this.preloadAssets = [
            { name: 'resources', url: 'assets/resources.gen.@3x.json' },
            { name: 'Trebuchet:n4,n5,i4,i5', url: 'assets/fonts/fonts.css' }
        ];

        this.transport = transport;
    }

    async load(context, event) {
        if (this.transport.closed)
            await new Promise(resolve => this.transport.on('connection', resolve))
    }

    async startGame(context, event) {
        return new Promise((resolve, reject) => {
            const gameScene = new GameScene(this.resources, this.transport);

            this.addScene('game', gameScene);

            const service = gameScene.createService({ parent: this.fsm });

            const done = () => {
                this.removeScene('game');
                resolve();
            };

            service.onDone(done).start();
        });
    }
}
