export default class Timer {
    constructor(view) {
        this.timerText = view.getChildByName('timer_text', true);
        this.timerMask = view.getChildByName('timer_slider_mask', true);

        this.maskPositions = {
            start: -126,
            end: -42
        };
    }

    start(targetTime) {
        this.timerMask.x = this.maskPositions.start;
        let deltaTime = targetTime - Date.now();

        if (deltaTime < 0)
            return;

        this.timerMask.addTween().to({ x: this.maskPositions.end }).duration(deltaTime);
        this.timerText.text = this._getTimeString(deltaTime);

        const updateInterval = setInterval(() => {
            deltaTime -= 1000;

            if (deltaTime <= 0) {
                this.timerText.text = '00:00';
                clearInterval(updateInterval);
                return;
            }

            this.timerText.text = this._getTimeString(deltaTime);
        }, 1000);
    }

    _getTimeString(milliseconds) {
        const date = new Date(milliseconds);
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${minutes}:${seconds}`;
    }
}