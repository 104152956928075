import { cellDrawNumberStyle, cellIdleNumberStyle } from '../view/TextStyles';

export default class Cell extends PIXI.Sprite {
    constructor(resources, id) {
        super(resources['cell_green']);

        this.anchor.set(0.5);
        this.name = `cell_${id}`;

        this._drawTexture = resources['cell_yellow'];
        this._idleTexture = resources['cell_green'];

        this.number = new PIXI.Text(id + 1, cellIdleNumberStyle);
        this.number.y = -5
        this.number.anchor.set(0.5);
        this.number.scale.set(0.5);
        this.addChild(this.number);
    }

    draw() {
        this.texture = this._drawTexture;
        this.number.style = cellDrawNumberStyle;
    }

    reset() {
        this.texture = this._idleTexture;
        this.number.style = cellIdleNumberStyle;
    }
}