export default {
    id: 'game_service',
    initial: 'init',
    context: {},
    states: {
        init: {
            invoke: {
                id: 'init',
                src: 'init',
                onDone: 'bet'
            }
        },
        bet: {
            entry: ['startTimer', 'toggleStateSwitchToBet'],
            on: {
                DRAW_RECEIVED: { target: 'draw' }
            }
        },
        draw: {
            entry: ['toggleStateSwitchToDraw'],
            invoke: {
                id: 'draw',
                src: 'draw',
                onDone: 'bet'
            }
            // on: {
            //     NEW_DRAW: { target: 'bet' }
            // }
        },
    }
};
