import { ViewBuilder, Scene } from 'core';
import gameSceneView from './view';
import game from './config/game';
import initDebug from 'core/debug';
import Cell from './components/Cell';
import Ball from './components/Ball';
import History from './components/History';
import Timer from './components/Timer';

const DRAW_DELAY = 10750;
const TIMER_DELAY = 2000;

export default class GameScene extends Scene {
    constructor(resources, transport) {
        super(gameSceneView, resources);

        this.resources = resources;
        this.transport = transport;

        this.history = new History(this.view);
        this.timer = new Timer(this.view);

        this.createLinksForViewObjects();
        this.timerSlider.mask = this.timerSliderMask;

        const url = new URL(window.location.href);

        this.kenoType = url.searchParams.get('type');

        this.changeKenoType();
        initDebug(this.view);
    }

    getConfig() {
        return game;
    }

    getOptions() {
        return {
            actions: {
                toggleStateSwitchToBet: () => this.toggleStateSwitch(true),
                toggleStateSwitchToDraw: () => this.toggleStateSwitch(false),
                startTimer: () => this.timer.start(this._timeToNextDraw)
            },
            services: {
                init: this.init.bind(this),
                draw: this.draw.bind(this)
            },
        };
    }

    async init() {
        for (let i = 0; i < 80; i++) {
            const cell = new Cell(this.resources, i);

            cell.position.set(i % 10 * 38, Math.floor(i / 10) * 37.3);
            this.cellsContainer.addChild(cell);
        }

        await this.transport.requestPromise('lottery_list_get', { type: this.kenoType, limit: 4 })
            .then(data => {
                console.warn(data);
                console.warn(data.lotteries[0].draw, data.lotteries[0].draws);
                this.history.setCurrentDraw(data.lotteries[0].draw);
                this._timeToNextDraw = data.lotteries[0].time - TIMER_DELAY;
                data.lotteries[0].draws.reverse().forEach(lottery => {
                    this.history.updateHistory(lottery);
                })
            });

        this.cells = this.cellsContainer.children;
        this.balls = [];

        for (let i = 0; i < 22; i++) {
            const ball  = new Ball(this.resources);

            ball.x = 471 - (ball.width * Math.PI * 8) - 21 * i;
            this.ballsContainer.addChild(ball);
            this.balls.push(ball);
        }

        this.transport.on('message', msg => this.processTransportMsg(msg));
    }

    async draw(ctx, evt) {
        const combination = evt.combination;

        this.balls.forEach((ball, i) => {
            ball.startRollingAnim(false);
            ball.addTween().to({ x: -20 - 21 * i }).duration(2000).onComplete(() => ball.stopRollingAnim());
        });

        await this.addTween().duration(2000).promise();

        for (let i = 0; i < combination.length; i++) {
            const ball = this.balls[i];
            const number = combination[i];
            const cell = this.cells[number - 1];

            ball.setNumber(number);
            ball.startRollingAnim();
            ball.addTween()
                .to({ x: 471 - 21 * i })
                .duration(2000)
                // .ease(PIXI.TweenEasing.Back.Out)
                .onComplete(() => {
                    ball.stopRollingAnim();
                    cell.draw();
                });

            await this.addTween().duration(600).promise();
        }

        await this.addTween().duration(DRAW_DELAY).promise();

        this.cells.forEach(cell => cell.reset());

        this.history.setCurrentDraw(this._newDraw);
        this.history.updateHistory(evt);
    }

    toggleStateSwitch(toBet = true) {
        this.stateSwitch.x = toBet ? 19 : -19;
    }

    createLinksForViewObjects() {
        this.map({
            cellsContainer: 'cells_container',
            logo: 'logo',
            paytable: 'paytable',
            stateContainer: 'state_container',
            timerContainer: 'timer_container',
            timerSlider: 'timer_slider',
            ballsContainer: 'balls_container',
            timerSliderMask: 'timer_slider_mask',
            stateSwitch: 'active_state_sprite'
        });
    }

    processTransportMsg(msg) {
        if (msg.data.type !== this.kenoType)
            return;

        switch(msg.event) {
            case 'lottery_combination_received':
                this.service.send('DRAW_RECEIVED', { draw: msg.data.draw, combination: msg.data.combination });
                console.log(msg.data)
                break;
            case 'lottery_draw_updated':
                // this.service.send('NEW_DRAW');
                console.log(msg.data)
                this._newDraw = msg.data.draw;
                this._timeToNextDraw = msg.data.time - TIMER_DELAY;
                break;
        }
    }

    changeKenoType() {
        const kenoTypes = {
            keno_22: 'keno_2',
            z_keno: 'keno_max',
            jx_keno: 'keno_tl',
            x_keno: 'keno_loto'
        };

        const kenoType = kenoTypes[this.kenoType];

        this.logo.texture = this.resources[kenoType + '_logo'];
        this.paytable.texture = this.resources[kenoType + '_paytable'];

        if (kenoType !== 'keno_2')
            this.ballsContainer.x = -370;
    }

    resize(screen) {
        this.stateContainer.x = this.logo.x + this.logo.width + this.stateContainer.width / 2 + 5;
        this.timerContainer.x = this.stateContainer.x + this.stateContainer.width / 2 + this.timerContainer.width / 2 + 5;
    }
}
