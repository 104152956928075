import HistoryView from './HistoryView';
import { stateLabelStyle, timerStyle } from './TextStyles';

export default {
    type: 'sprite',
    name: 'game_scene',
    children: [
        {
            type: 'sprite',
            name: 'background',
            texture: 'background',
            anchor: 0.5,
            scale: 1.03
        },
        {
            type: 'sprite',
            name: 'header',
            texture: 'top_panel_background',
            anchor: { x: 0, y: 0.5 },
            x: -320,
            y: -167,
            children: [
                {
                    type: 'sprite',
                    name: 'logo',
                    texture: 'keno_2_logo',
                    anchor: { x: 0, y: 0.5 },
                },
                {
                    type: 'sprite',
                    name: 'state_container',
                    texture: 'state_background',
                    anchor: 0.5,
                    children: [
                        {
                            type: 'sprite',
                            name: 'active_state_sprite',
                            texture: 'state_sprite',
                            anchor: 0.5,
                            x: -19
                        },
                        {
                            type: 'text',
                            name: 'draw_state_label',
                            text: 'розыгрыш',
                            style: stateLabelStyle,
                            anchor: 0.5,
                            scale: 0.5,
                            x: -19,
                            y: -1
                        },
                        {
                            type: 'text',
                            name: 'bet_state_label',
                            text: 'ставки',
                            style: stateLabelStyle,
                            anchor: 0.5,
                            scale: 0.5,
                            x: 19,
                            y: -1
                        }
                    ]
                },
                {
                    type: 'sprite',
                    name: 'timer_container',
                    texture: 'timer_background',
                    anchor: 0.5,
                    children: [
                        {
                            type: 'sprite',
                            name: 'timer_slider',
                            texture: 'timer_slider',
                            anchor: 0.5
                        },
                        {
                            type: 'text',
                            name: 'timer_text',
                            anchor: 0.5,
                            scale: 0.5,
                            text: '00:00',
                            style: timerStyle
                        },
                        {
                            type: 'graphics',
                            name: 'timer_slider_mask',
                            x: -42,
                            draw: target => {
                                target.beginFill(0xFF0000)
                                    .drawRoundedRect(0, -32 / 6, 250 / 3 + 1, 32 / 3, 3)
                                    .endFill();
                            }
                        }
                    ]
                }
            ]
        },
        {
            type: 'sprite',
            name: 'balls_container',
            texture: 'balls_background',
            anchor: { x: 0, y: 0.5 },
            x: -320,
            y: -138,
            children: []
        },
        {
            type: 'container',
            name: 'cells_container',
            x: -295,
            y: -103,
            children: []
        },
        HistoryView,
        {
            type: 'sprite',
            name: 'paytable',
            texture: 'keno_2_paytable',
            anchor: 0.5,
            x: 193,
            y: 90
        },
        {
            type: 'sprite',
            name: 'blueprint',
            texture: 'blueprint',
            anchor: 0.5,
            alpha: 0
        }
    ],
};
