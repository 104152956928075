import { historyColumnsStyle, historyHeaderStyle } from './TextStyles'

export default {
    type: 'sprite',
    name: 'history_container',
    texture: 'history_background',
    anchor: 0.5,
    x: 193,
    y: -59,
    children: [
        {
            type: 'text',
            name: 'history_header',
            text: 'ИСТОРИЯ (Текущий тираж: 210427)',
            anchor: { x: 0, y: 0.5 },
            scale: 0.5,
            x: -110,
            y: -51,
            style: historyHeaderStyle
        },
        {
            type: 'text',
            name: 'col_1_header',
            text: 'Тираж',
            anchor: 0.5,
            scale: 0.5,
            x: -100,
            y: -30,
            style: historyColumnsStyle
        },
        {
            type: 'text',
            name: 'col_2_header',
            text: 'Шары',
            anchor: 0.5,
            scale: 0.5,
            x: -63,
            y: -30,
            style: historyColumnsStyle
        },
        {
            type: 'graphics',
            name: 'dividers',
            y: -20,
            draw: target => {
                target.lineStyle(1, 0xADADA6);

                for (let i = 0; i < 5; i++) {
                    target.moveTo(-731 / 6 + 1.8, i * 19);
                    target.lineTo(731 / 6 - 1.8, i * 19);
                }

                target.moveTo(-80, -20);
                target.lineTo(-80, 81.4)
            }
        },
        {
            type: 'container',
            name: 'history_lines',
            children: []
        }
    ]
}