const stateLabelStyle = {
    fontFamily: 'Trebuchet',
    fontSize: 16,
    fill: 0x313C3C
};

const cellIdleNumberStyle = {
    fontFamily: 'Trebuchet',
    fontSize: 52,
    fill: 0xFFFFFF,
    dropShadow: true,
    dropShadowDistance: 1,
    dropShadowBlur: 2
};

const cellDrawNumberStyle = {
    fontFamily: 'Trebuchet',
    fontSize: 52,
    fill: 0x782F15,
    dropShadow: true,
    dropShadowDistance: 1,
    dropShadowBlur: 2,
    dropShadowColor:'#7A5023'
};

const timerStyle = {
    fontFamily: 'Trebuchet',
    fill: 0xFFFFFF,
    fontSize: 19,
    dropShadow: true,
    dropShadowDistance: 1,
};

const historyHeaderStyle = {
    fontFamily: 'Trebuchet',
    fill: 0x792C15,
    fontSize: 20,
};

const historyColumnsStyle = {
    fontFamily: 'Trebuchet',
    fill: 0x666666,
    fontSize: 17,
};

const historyContentStyle = {
    fontFamily: 'Trebuchet',
    fill: 0x000000,
    fontSize: 18,
};

const ballNumberStyle = {
    fontFamily: 'Trebuchet',
    fill: 0x792C15,
    fontSize: 30,
}

export {
    stateLabelStyle,
    cellIdleNumberStyle,
    cellDrawNumberStyle,
    timerStyle,
    historyHeaderStyle,
    historyColumnsStyle,
    historyContentStyle,
    ballNumberStyle
}